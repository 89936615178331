/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzE1MzI0OSIsIj9lcjwjYjljMmM4IiwiO2VyPCMwYzFmMzF$LCIlPmBePCMwMDg2YTgiLCI~ZXI8I2IzZGJlNSIsIjtlcjwjMDA2OThkfiwid2Fybj5gXjwjZmYzYzAwIiwiP2VyPCNmZmM1YjMiLCI7ZXI8I2ZmMjcwMH4sIj9UZXh0PCMwYzFmMzEiLCI~PTwjZjhmOGY4IiwiO1RleHQ8I2Y4ZjhmOCIsIjs9PCMwYzFmMzF$LCJmb250cz5bYEA8KC00IiwiZmFtaWx5PFBvcHBpbnN$LGBAPCgtMyIsImZhbWlseTxQb3BwaW5zfixgQDwoLTIiLCJmYW1pbHk8UG9wcGluc34sYEA8KC0xIiwiZmFtaWx5PFBvcHBpbnN$LGBAPGhlYWRsaW5lIiwiZmFtaWx5PFBvcHBpbnN$LGBAPHRpdGxlIiwiZmFtaWx5PFBvcHBpbnN$LGBAPHN1YiktMiIsImZhbWlseTxQb3BwaW5zfixgQDxzdWIpLTEiLCJmYW1pbHk8UG9wcGlucyIsInNpemU$MTZ9LGBAPGJvZHktMiIsImZhbWlseTxJQk0gUGxleCBTYW5zIiwic2l6ZT4xNn0sYEA8Ym9keS0xIiwiZmFtaWx5PElCTSBQbGV4IFNhbnN$LGBAPGJ1dHRvbiIsImZhbWlseTxJQk0gUGxleCBTYW5zIiwidmFyaWFudDxyZWd1bGFyfixgQDxjYXB0aW9uIiwiZmFtaWx5PElCTSBQbGV4IFNhbnMiLCJzaXplPjE2fSxgQDxpbnB1dCIsImZhbWlseTxJQk0gUGxleCBTYW5zIiwic2l6ZT5udWxsfV0sImljb25zPEZpbGxlZCIsIj9uZXNzPnRydWUsInZlcnNpb24$MTJ9
*/

@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
//@import 'https://fonts.googleapis.com/css?family=Material+Icons';
//@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500');
//@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,500');

$fontConfig: (
  display-4: mat.define-typography-level(112px, 112px, 300, "Poppins", -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, "Poppins", -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, "Poppins", 0em),
  display-1: mat.define-typography-level(34px, 40px, 400, "Poppins", 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, "Poppins", 0em),
  title: mat.define-typography-level(20px, 32px, 500, "Poppins", 0.0075em),
  subheading-2: mat.define-typography-level(16px, 28px, 400, "Poppins", 0.0094em),
  subheading-1: mat.define-typography-level(16px, 24px, 500, "Poppins", 0.0063em),
  body-2: mat.define-typography-level(16px, 24px, 500, "IBM Plex Sans", 0.0156em),
  body-1: mat.define-typography-level(14px, 20px, 400, "IBM Plex Sans", 0.0179em),
  button: mat.define-typography-level(14px, 14px, 400, "IBM Plex Sans", 0.0893em),
  caption: mat.define-typography-level(16px, 20px, 400, "IBM Plex Sans", 0.025em),
  input: mat.define-typography-level(inherit, 1.125, 400, "IBM Plex Sans", 1.5px),
);

// Foreground Elements

// Light Theme Text
$dark-text: #0c1f31;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #f8f8f8;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #f8f8f8;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#0c1f31, 20%);
$dark-bg-alpha-4: rgba(#0c1f31, 0.04);
$dark-bg-alpha-12: rgba(#0c1f31, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #0c1f31;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#f8f8f8, 0.04);
$light-bg-alpha-12: rgba(#f8f8f8, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($fontConfig);`
@include mat.all-legacy-component-typographies($fontConfig);
@include mat.legacy-core();

// Theme Config

body {
  --primary-color: #153249;
  --primary-lighter-color: #b9c2c8;
  --primary-darker-color: #0c1f31;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary: (
  main: #153249,
  lighter: #b9c2c8,
  darker: #0c1f31,
  200: #153249,
  // For slide toggle,
  contrast: (main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #0086a8;
  --accent-lighter-color: #b3dbe5;
  --accent-darker-color: #00698d;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
  main: #0086a8,
  lighter: #b3dbe5,
  darker: #00698d,
  200: #0086a8,
  // For slide toggle,
  contrast: (main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #ff3c00;
  --warn-lighter-color: #ffc5b3;
  --warn-darker-color: #ff2700;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: #ff3c00,
  lighter: #ffc5b3,
  darker: #ff2700,
  200: #ff3c00,
  // For slide toggle,
  contrast: (main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$theme: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  is-dark: false,
  foreground: $mat-light-theme-foreground,
  background: $mat-light-theme-background,
);
$altTheme: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  is-dark: true,
  foreground: $mat-dark-theme-foreground,
  background: $mat-dark-theme-background,
);

// Theme Init
@include mat.all-legacy-component-themes($theme);

//.theme-alternate {
//// @include mat.all-component-themes($altTheme);
//}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.mat-table {
  width: 100%;
}

.mat-row:nth-child(even) {
  background-color: #e0e8ef;
}

.mat-cell:first-of-type {
	padding-left: 24px !important;
}

.mat-cell {
  padding-left: 4px !important;
}